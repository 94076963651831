import { RemixBrowser } from '@remix-run/react'
import { startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'

function hydrate(): void {
  startTransition(() => {
    hydrateRoot(document, <RemixBrowser />)
  })
}

window.onerror = (...args: any[]) => {
  // eslint-disable-next-line no-console
  console.error(`[error]`, ...args)
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  window.setTimeout(hydrate, 1)
}
